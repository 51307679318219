import type { Locale } from '@/i18n/locale';
import { getRelativeLocaleUrl } from 'astro:i18n';
// @ts-ignore
import type { NavbarItem } from '@/components/SidebarNav.astro';
import type { AssessmentResponse } from '@/lib/gs-api/serializers/loops';
import { t } from 'i18next';
import type { User } from '@sentry/astro';
import { Loops } from './lib/gs-api/serializers/loops';

/**
 *
 * @param color the color to modify, in hex format (e.g. "#ffffff")
 * @param percent the percentage amount to tint the colour.
 *                to lighten, use positive number from 1 to 100.
 *                to darken, use negative number from -1 to -100.
 * @returns hex code of resulting color
 */
export function tintColor(color: string, percent: number) {
	const num = parseInt(color.replace('#', ''), 16);
	const amt = Math.round(2.55 * percent);
	const R = (num >> 16) + amt;
	const B = ((num >> 8) & 0x00ff) + amt;
	const G = (num & 0x0000ff) + amt;

	return (
		'#' +
		(
			0x1000000 +
			(R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
			(B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
			(G < 255 ? (G < 1 ? 0 : G) : 255)
		)
			.toString(16)
			.slice(1)
	);
}

export function hexToRGB(hexCode: string) {
	let hex = hexCode.replace('#', '');

	if (hex.length === 3) {
		hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	}

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	return `${r}, ${g}, ${b}`;
}

export const PageNotFoundResponse = new Response('Page not found', {
	status: 404,
});

export const formatDateByLocale = (
	date: Date | null,
	locale: Locale,
	options: {
		dateOptions?: Intl.DateTimeFormatOptions;
		isDateOnly?: boolean;
	} = {
		dateOptions: {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		},
	}
) => {
	if (!date) return date;
	const localesMap = {
		en: 'en-US',
		es: 'es-US',
	};
	let timeZone;

	// `isDateOnly` is useful when the date has no time and we want to display it as it is.
	// js adds time when parsing a string as a date only, i.e '2021-09-01' -> '2021-09-01T00:00:00Z'.
	// Using `toLocaleDateString` could convert the timezones resulting the date to be off by one day.
	// To fix this we set the timezone to UTC to avoid any timezone conversion.
	if (options.isDateOnly) {
		timeZone = 'UTC';
	}

	return date.toLocaleDateString(localesMap[locale], {
		...options.dateOptions,
		...(timeZone ? { timeZone } : {}),
	});
};

/**
 * Get the URL to a public asset including the CDN URL if necessary
 */
const prefix = import.meta.env.ASSETS_PREFIX || '';
export function getAssetUrl(path: string) {
	return prefix + path;
}

/**
 * Set a cookie
 * @param name string - name of the cookie
 * @param value string - value of the cookie
 * @param days number - number of days until the cookie expires
 */
export function setCookie(name: string, value: string, days: number): void {
	const date = new Date();
	date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
	document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

/**
 * Get a cookie
 * @param name string - name of the cookie
 * @returns string | null - value of the cookie or null if it doesn't exist
 */
export function getCookie(name: string): string | null {
	const nameEQ = `${name}=`;
	const cookies = document.cookie.split(';');
	for (let cookie of cookies) {
		cookie = cookie.trim();
		if (cookie.startsWith(nameEQ)) return cookie.substring(nameEQ.length);
	}
	return null;
}

/**
 * Delete a cookie
 * @param name string - name of the cookie
 */
export function deleteCookie(name: string): void {
	setCookie(name, '', -1);
}

/**
 * Check if a link is an external URL
 * @param link string - URL to check
 * @returns boolean - true if the link is an external URL
 */
export function isExternalUrl(link: string) {
	const url = new URL(link);

	return (
		url &&
		/^https?:\/\//i.test(url.href) &&
		!(
			url.hostname.endsWith(location.hostname.replace(/^www\./, '')) ||
			url.hostname.endsWith('typeform.com')
		)
	);
}

export function generateSidebarTabs(options: {
	locale: Locale;
	appUrl: string;
	user: User | null;
	newLoop?: Loops;
	assessmentResponses?: AssessmentResponse[];
	featureFlags?: {
		enablePopHealthLoops: boolean;
		enablePopHealthProgressPage2: boolean;
	};
}): Array<NavbarItem> {
	const itemsList = [];
	const { locale, appUrl, user, newLoop, assessmentResponses, featureFlags } =
		options;

	if (user?.isParticipant()) {
		const { patientId } = user.profileDetails;

		if (featureFlags?.enablePopHealthLoops) {
			itemsList.push({
				href: getRelativeLocaleUrl(locale, 'loops/new'),
				title: t('navbar.newLoop', 'New Loop'),
				icon: 'bi:plus-lg',
				titleClasses: ['fs-6'],
				tooltip:
					newLoop &&
					!newLoop.canDeliverAssessment &&
					newLoop.dateCanDeliverAssessmentNext
						? t(
								'navbar.nextAvailableDate',
								'Your next Loop will be available on {{date}}.',
								{
									date: formatDateByLocale(
										new Date(newLoop.dateCanDeliverAssessmentNext),
										locale,
										{
											dateOptions: {
												day: 'numeric',
												month: 'long',
												year: 'numeric',
												hour: 'numeric',
												minute: 'numeric',
												timeZoneName: 'short',
											},
										}
									) as string,
								}
						  )
						: undefined,
				isDisabled: !newLoop?.canDeliverAssessment,
				path: /^\/(en|es)\/loops\/new+\/?$/,
			});
			itemsList.push({
				href: getRelativeLocaleUrl(locale, 'loops'),
				title: t('navbar.loops', 'Loops'),
				icon: 'bi:arrow-clockwise',
				titleClasses: ['fs-6'],
				isDisabled: newLoop?.assessmentResponses.length === 0,
				tooltip:
					newLoop?.assessmentResponses.length === 0
						? t('navbar.noLoops', "You haven't saved any Loops yet.")
						: undefined,
				path: /^\/(en|es)\/loops\/?([a-zA-Z0-9-]{36})?\/?$/,
				children:
					assessmentResponses?.map((assessmentResponse): NavbarItem => {
						return {
							href: getRelativeLocaleUrl(
								locale,
								`loops/${assessmentResponse.id}`
							),
							title: assessmentResponse.submitted,
							isDate: true,
							titleClasses: ['fs-7'],
							path: new RegExp(`^/(en|es)/loops/${assessmentResponse.id}/?$`),
						};
					}) ?? [],
			});
		} else {
			itemsList.push({
				href: `${appUrl}/self_guided/${patientId}`,
				title: t('navbar.dashbord', 'Dashboard'),
				icon: 'bi:house',
				titleClasses: ['fs-6'],
			});
		}
		itemsList.push({
			href: featureFlags?.enablePopHealthProgressPage2
				? getRelativeLocaleUrl(locale, 'progress')
				: `${appUrl}/self_guided/${patientId}/results`,
			title: t('navbar.progress', 'Progress'),
			icon: 'bi:graph-up-arrow',
			titleClasses: ['fs-6'],
			path: /^\/(en|es)\/progress\/?$/,
		});
		itemsList.push({
			href: getRelativeLocaleUrl(locale, 'tools-and-resources'),
			title: t('navbar.resources', 'Resources'),
			icon: 'bi:book',
			titleClasses: ['fs-6'],
			path: /^\/(en|es)\/tools-and-resources\/?$/,
		});
	}

	return itemsList;
}
